<template>
	<div class="content">
		<div class="search b-w flex f-ai-c pr">
			<i class="search-icon"></i>
			<form action="javascript:;" @keyup.enter="search()">
				<input placeholder="搜索医生、医院、疾病" v-model="keywords" class="input" />
			</form>
			<span class="cancel" @click="cancel">取消</span>
		</div>
		<!-- <h1>{{keywords}}</h1> -->
		<div class="wrap">
			<template v-if="doctors.length">
				<div class="doctor flex" v-for="(item, index) in doctors" :key="index" @click="toDetail(item.id)">
					<img :src="item.avatar || defDoctor" alt="">
					<div class="doctor-info">
						<div class="name-info">
							<h1>{{item.name}}</h1>
							<h1>{{item.titleName}}</h1>
							<span v-if="item.price !== undefined && item.price !== null">￥ {{item.price}}/次</span>
						</div>
						<span class="hospital">{{item.hospitalName}}</span>
						<div class="diseases">
							<span v-for="(it, ind) in item.goodAt" :key="ind">{{it}}</span>
						</div>
						<div class="app flex f-ai-c">
							<span>{{item.payCount || 0}}</span>人付款
							<span class="ml19">{{item.appraiseCount || 0}}</span>人评价
							<span class="ml19">{{item.goodApp || 100}}%</span>好评
						</div>
						<div class="good-at">
							<i>擅长：</i>
							<div>
								{{item.goodAt.join('、') || '暂无擅长'}}
							</div>
						</div>
					</div>
				</div>
			</template>
			<no-data v-else-if="!doctors.length && !showHistory"></no-data>
		</div>
		<div v-if="sh.length && showHistory" class="history">
			<h1>常用搜索</h1>
			<ul class="flex">
				<li v-for="(item, index) in sh" :key="index" @click="historyClick(item.word)">{{item.word}}</li>
			</ul>
		</div>
	</div>
</template>
<script>
import { searchDoctor, searchHistory } from '@/request/api/doctor';
import noData from '@/views/common/noData.vue';

export default {
	data () {
		return {
			keywords: '',
			doctors: [],
			defDoctor: require('@/assets/images/doctor-def.png'),
			sh: [],
			showHistory: true
		};
	},
	components: { noData },
	created () {
		this.keywords = this.$route.query.diseaseName || '';
		this.getSearchHistory();
		if (this.keywords) {
			this.search('dis');
		}
	},
	methods: {
		search (type) {
			searchDoctor({ type, keywords: this.keywords }).then(data => {
				if (data && data === 'retry') {
					this.search(type);
				} else if (data && data.length) {
					this.doctors = data.map(d => {
						let price = 0;
						for (let i = 0; i < d.services.length; i++) {
							if (d.services[i].price > price) {
								price = d.services[i].price;
							}
						}
						d.price = price;
						return d;
					});
				} else {
					this.doctors = [];
				}
				this.showHistory = false;
			}).catch(e => {
				this.$root.elMsg(e.message, 'fail');
			});
		},
		cancel () {
			if (this.keywords) {
				this.keywords = '';
				this.showHistory = true;
			} else {
				this.$root.go(-1);
			}
		},
		toDetail (id) {
			this.$root.go('/doctor-detail', { doctorId: id });
		},
		getSearchHistory () {
			searchHistory().then(data => {
				if (data && data === 'retry') {
					this.getSearchHistory();
				} else if (data && data.length) {
					this.sh = data;
				} else {
					this.sh = [];
				}
			}).catch(e => {
				this.$root.elMsg(e.message, 'fail');
			});
		},
		historyClick (word) {
			this.keywords = word;
			this.search();
		}
	}
};
</script>
<style lang="less" scoped>
	.content {
		display: flex;
		flex-direction: column;
		overflow: hidden;
		height: 100%;
	}
	.search {
		height: 49px;
		padding: 0 12px;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		form {
			width: 100%;
			.input {
				width: 90%;
				height: 33px;
				line-height: 33px;
				background: #F3F4F6;
				border-radius: 16.5px;
				padding-left: 34px;
				color: #232830;
				outline: none;
				border: none;
			}
		}
		.search-icon {
			content: '';
			background: url('../../assets/images/search-icon.png') no-repeat;
			background-size: contain;
			position: absolute;
			width: 14px;
			height: 14px;
			display: inline-block;
			left: 26px;
		}
		.cancel {
			width: 50px;
			text-align: right;
		}
	}
	.wrap {
		flex: 1;
		overflow: auto;
		margin: 0;
		padding-bottom: 12px;
		padding-top: 6px;
	}
	.doctor {
		margin: 0 12px;
		background: #ffffff;
		border-radius: 6px;
		padding: 15px 10px;
		img {
			width: 50px;
			height: 50px;
			border-radius: 50%;
			margin-right: 10px;
		}
		&-info {
			flex: 1;
			.name-info {
				h1 {
					font-size: 16px;
					font-family: PingFangSC-Medium, PingFang SC;
					font-weight: 500;
					color: #232830;
					display: inline-block;
					margin-right: 12px;
				}
				span {
					float: right;
					margin-right: 10px;
					color: #F33D3A;
					font-size: 14px;
				}
			}
			.hospital {
				font-size: 13px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #7D7D7D;
				margin-top: 3.7px;
				margin-bottom: 10px;
			}
			.diseases {
				span {
					height: 20px;
					background: #F7F7F7;
					border-radius: 10px;
					display: inline-block;
					text-align: center;
					line-height: 20px;
					padding: 0 8px;
					margin-right: 7px;
					margin-top: 7px;
					font-size: 11px;
				}
			}
			.app {
				height: 25px;
				background: #FFF9F2;
				border-radius: 4px;
				font-size: 10px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #9396A0;
				margin-top: 10px;
				padding: 0 15px;
				span {
					font-size: 14px;
					font-family: PingFangSC-Medium, PingFang SC;
					font-weight: 500;
					color: #FF8D16;
					margin-right: 2px;
				}
				.ml19 {
					margin-left: 10px;
				}
			}
			.good-at {
				margin-top: 8px;
				position: relative;
				font-size: 12px;
				i {
					position: absolute;
					color: #232830;
					font-weight: 600;
				}
				div {
					text-indent: 50px;
					color: #9396A0;
					word-break: break-all;
					text-overflow: ellipsis;
					overflow: hidden;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
				}
			}
		}
	}
	.history {
		background: #FFFFFF;
		padding: 20px 0 0 15px;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		top: 58px;
		h1 {
			font-size: 16px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #3B3D40;
			margin-bottom: 20px;
		}
		ul {
			flex-wrap: wrap;
			li {
				min-width: 30px;
				margin-top: 4px;
				height: 25px;
				line-height: 25px;
				background: #F7F7F7;
				border-radius: 15px;
				text-align: center;
				padding: 0 15px;
				box-sizing: border-box;
				margin-right: 15px;
				font-size: 13px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #3B3D40;
			}
		}
	}
</style>