var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content" }, [
    _c("div", { staticClass: "search b-w flex f-ai-c pr" }, [
      _c("i", { staticClass: "search-icon" }),
      _c(
        "form",
        {
          attrs: { action: "javascript:;" },
          on: {
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.search()
            },
          },
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.keywords,
                expression: "keywords",
              },
            ],
            staticClass: "input",
            attrs: { placeholder: "搜索医生、医院、疾病" },
            domProps: { value: _vm.keywords },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.keywords = $event.target.value
              },
            },
          }),
        ]
      ),
      _c("span", { staticClass: "cancel", on: { click: _vm.cancel } }, [
        _vm._v("取消"),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "wrap" },
      [
        _vm.doctors.length
          ? _vm._l(_vm.doctors, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "doctor flex",
                  on: {
                    click: function ($event) {
                      return _vm.toDetail(item.id)
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: { src: item.avatar || _vm.defDoctor, alt: "" },
                  }),
                  _c("div", { staticClass: "doctor-info" }, [
                    _c("div", { staticClass: "name-info" }, [
                      _c("h1", [_vm._v(_vm._s(item.name))]),
                      _c("h1", [_vm._v(_vm._s(item.titleName))]),
                      item.price !== undefined && item.price !== null
                        ? _c("span", [
                            _vm._v("￥ " + _vm._s(item.price) + "/次"),
                          ])
                        : _vm._e(),
                    ]),
                    _c("span", { staticClass: "hospital" }, [
                      _vm._v(_vm._s(item.hospitalName)),
                    ]),
                    _c(
                      "div",
                      { staticClass: "diseases" },
                      _vm._l(item.goodAt, function (it, ind) {
                        return _c("span", { key: ind }, [_vm._v(_vm._s(it))])
                      }),
                      0
                    ),
                    _c("div", { staticClass: "app flex f-ai-c" }, [
                      _c("span", [_vm._v(_vm._s(item.payCount || 0))]),
                      _vm._v("人付款 "),
                      _c("span", { staticClass: "ml19" }, [
                        _vm._v(_vm._s(item.appraiseCount || 0)),
                      ]),
                      _vm._v("人评价 "),
                      _c("span", { staticClass: "ml19" }, [
                        _vm._v(_vm._s(item.goodApp || 100) + "%"),
                      ]),
                      _vm._v("好评 "),
                    ]),
                    _c("div", { staticClass: "good-at" }, [
                      _c("i", [_vm._v("擅长：")]),
                      _c("div", [
                        _vm._v(
                          " " +
                            _vm._s(item.goodAt.join("、") || "暂无擅长") +
                            " "
                        ),
                      ]),
                    ]),
                  ]),
                ]
              )
            })
          : !_vm.doctors.length && !_vm.showHistory
          ? _c("no-data")
          : _vm._e(),
      ],
      2
    ),
    _vm.sh.length && _vm.showHistory
      ? _c("div", { staticClass: "history" }, [
          _c("h1", [_vm._v("常用搜索")]),
          _c(
            "ul",
            { staticClass: "flex" },
            _vm._l(_vm.sh, function (item, index) {
              return _c(
                "li",
                {
                  key: index,
                  on: {
                    click: function ($event) {
                      return _vm.historyClick(item.word)
                    },
                  },
                },
                [_vm._v(_vm._s(item.word))]
              )
            }),
            0
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }